<template>
  <div
    class="col-lg-4 col-md-12"
    v-for="(footer, key) in FooterGroup"
    :key="key"
  >
    <a href="../"> <ICON_WSLogo /></a>
    <h5 class="pt-4">{{ footer.location_title }}:</h5>
    <p>Sydney, Australia<br />Chennai, India</p>
    <h5>{{ footer.contact_title }}:</h5>
    <p>
      <a :href="`tel:+${footer.contact_1_link}`">{{ footer.contact_1 }}</a>
      <br />
      <a :href="`tel:+${footer.contact_2_link}`">{{ footer.contact_2 }}</a>
      <br />
      <a :href="`mailto:${footer.email_link}`">{{ footer.email }}</a>
    </p>
  </div>
</template>

<script>
import ICON_WSLogo from "@/assets/icons/WSLogo.vue";
import axios from "axios";
export default {
  components: {
    ICON_WSLogo,
  },
  data() {
    return {
      lang: "en",
      FooterGroup: [],
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/footer/collections/group-1/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.FooterGroup = [];
            return;
          } else if (data.data.length == 0) {
            this.FooterGroup = [];
            return;
          }
          this.FooterGroup = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
