<template>
  <footer id="footer">
    <div class="container-fluid">
      <div class="row footerContent pt-4">
        <!--Group 1 -->
        <FooterGroup1 />
        <FooterGroup2 />
        <FooterGroup3 />
        <FooterGroup4 />
        <FooterGroup5 />
      </div>
      <hr />
      <div class="desktopview">
        <div class="row">
          <div class="col-lg-12 col-md-12 text-center">
            <ul class="footermenu">
              <li class="footerborder">
                Copyrights &copy; 2022, All Rights Reserved
              </li>
              <!-- <li class="footerborders"><a href="../sitemap">Site Map</a></li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="mobileview">
        <div class="row">
          <div class="col-lg-12 col-md-12 text-center copyrightsfooter">
            <ul class="footermenu">
              <li class="footerborder">
                Copyrights &copy; 2022, All Rights Reserved
              </li>
              <!-- <li class="footerborders"><a href="../sitemap">Site Map</a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- #footer -->
  <div id="components-back-top-demo-custom">
    <a-back-top>
      <div class="ant-back-top-inner">
        <ICON_UpArrow :size="icon_size_upArrow" :color="upArrow_color" />
      </div>
    </a-back-top>
  </div>
</template>
<script>
import { whatsapp_api, url_privacy, url_tos } from "@/constants/constant.js";
import ICON_UpArrow from "@/assets/icons/UpArrow.vue";
import FooterGroup1 from "@/components/footer/Group-1.vue";
import FooterGroup2 from "@/components/footer/Group-2.vue";
import FooterGroup3 from "@/components/footer/Group-3.vue";
import FooterGroup4 from "@/components/footer/Group-4.vue";
import FooterGroup5 from "@/components/footer/Group-5.vue";
export default {
  components: {
    ICON_UpArrow,
    FooterGroup1,
    FooterGroup2,
    FooterGroup3,
    FooterGroup4,
    FooterGroup5,
  },
  data() {
    return {
      footer_color: "black",
      upArrow_color: "white",
      icon_size: 24,
      icon_size_logo: 20,
      icon_size_upArrow: 90,
      whatsapp_api,
      url_privacy,
      url_tos,
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 4000);
    },
  },
};
</script>
<style scoped>
#components-back-top-demo-custom .ant-back-top {
  bottom: 100px;
}
#components-back-top-demo-custom .ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 28px;
  border-radius: 50px;
  background-color: #0880e8;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
@media (max-width: 992px) {
  .copyrightsfooter {
    padding: 0px 0px 90px 0px !important;
  }
  .footerContent {
    padding: 0px 30px !important;
  }
  .desktopview {
    display: none !important;
  }
  .mobileview {
    display: block !important;
  }
}
.mobileview {
  display: none;
}
.desktopview {
  display: block;
}
h6 {
  font-size: 20px;
}
p {
  font-size: 16px;
}
.socialfooter {
  text-align: right;
  padding: 0px 30px;
}
ul.social li {
  display: inline-block;
  margin: 8px;
}
ul.social li a {
  font-size: 16px;
  color: #000;
  line-height: 20px;
  letter-spacing: 1px;
  list-style-type: none;
}
.social-links a {
  display: inline-block;
  color: #000;
  padding: 8px;
  margin-right: 10px;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
.social-links a:hover {
  color: #0880e8;
}
@media (max-width: 991px) {
  .footerContent > div {
    padding-top: 20px;
  }
}

/*======================================
	Footer CSS
========================================*/
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  position: relative;
  box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 5%);
  background: #fff;
  padding: 10px 30px 0px 30px;
  color: #000000;
  font-size: 14px;
}

#footer .footer-top {
  background: #fff;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 26px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  color: #000;
}

#footer .footer-top {
  height: 40px;
  margin-bottom: 10px;
}

#footer .footer-top .footer-info p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: #000;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 5px;
  color: #f82249;
  font-size: 18px;
}

#footer .footer-top .footer-links ul li {
  border-bottom: 1px solid #262c44;
  padding: 10px 0;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #000;
}

#footer .footer-top .footer-links ul a:hover {
  color: #0880e8;
}

#footer .copyright {
  text-align: center;
  font-size: 12px;
}

#footer .credits {
  text-align: center;
  font-size: 13px;
  color: #ddd;
}
@media (max-width: 1199px) {
  #header .container {
    max-width: 100%;
  }

  .nav-menu a {
    padding: 8px 4px;
  }
  .footermenu > li {
    padding: 5px !important;
  }
}

@media (max-width: 991px) {
  #footer {
    padding: 0px;
    font-size: 35px !important;
  }
  li.footerborder {
    font-size: 16px;
    list-style: none;
  }
  li.footerborders {
    font-size: 16px;
    list-style: none;
  }
  #footer .copyright {
    font-size: 16px !important;
    margin-bottom: 80px;
  }
  ul.social li a {
    font-size: 30px !important;
    line-height: 40px !important;
  }
  ul.social li {
    margin: 30px !important;
  }
  .social-links a {
    width: 35px !important;
    height: 35px !important;
    padding: 8px 6px !important;
    margin-right: 10px;
  }
  li.footerborder > a:hover {
    font-size: 16px !important;
  }
  ul.footermenu {
    display: block !important;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
}
@media only screen and (min-width: 800px) {
  ul.footermenu {
    display: flex;
    padding: 10px;
  }
  .footermenu > li {
    padding: 0 10px;
    margin-bottom: 10px;
    list-style: none;
  }
}
/* li.footerborder {
  border-right: 1px solid #ccc;
} */
li.footerborder > a {
  color: #000;
}
li.footerborders > a {
  color: #000;
}
li.footerborder > a:hover {
  color: #0880e8;
}
li.footerborders > a:hover {
  color: #0880e8;
}
@media only screen and (max-width: 991px) and (min-width: 0px) {
  li.footerborder {
    border: none;
  }
}
</style>
