<template>
  <div
    class="col-lg-2 col-md-3 col-6"
    v-for="(footer, key) in FooterGroup1"
    :key="key"
  >
    <h6 class="pt-2" style="font-weight: 600">{{ footer.title }}</h6>
    <p class="pt-4">
      <a href="/business-service">{{ footer.subtitle_1 }}</a>
    </p>
    <p>
      <a href="/enterprise-service">{{ footer.subtitle_2 }}</a>
    </p>
    <p>
      <a href="/automation-service">{{ footer.subtitle_3 }}</a>
    </p>
    <p>
      <a href="/data-visualization">{{ footer.subtitle_4 }}</a>
    </p>
    <p>
      <a href="/insights-reports">{{ footer.subtitle_5 }}</a>
    </p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      lang: "en",
      FooterGroup1: [],
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/footer/collections/group-2/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.FooterGroup1 = [];
            return;
          } else if (data.data.length == 0) {
            this.FooterGroup1 = [];
            return;
          }
          this.FooterGroup1 = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
